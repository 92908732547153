const ConcertReviews = [
  {
    Title: "Bernstein’s “Arias and Barcarolles” with the Cincinnatti Chamber Orchestra",
    Review: "DiSimone showed a graceful mezzo with pleasing color and personality. The tuneful “Greeting” glowed with an easy gracefulness.",
    Author: "Cincinnati Enquirer",
  },
  {
    Title: "Bach B Minor Mass with the Pro Arte Chorale and Orchestra",
    Review: "Mezzo Soprano Lorraine DiSimone communicated meaning with her lustrous agility. Her phrasing in the “Agnus Dei” was moving.",
    Author: "Newark Star Ledger",
  },
  {
    Title: "Handel’s Messiah with Masterworks Chorus and Orchestra (Carnegie Hall)",
    Review: "Mezzo-Soprano Lorraine DiSimone had some wonderful elaborations on the melody in ‘O Thou that Tellest’ and exhibited a snappy agility in ‘Thou art gone up on high’.",
    Author: "Newark Star Ledger",
  },
  {
    Title: "Copland In the Beginning with Bel Canto of Greensboro, NC",
    Review: "One of the most appealing aspects of this performance was the appearance of Lorraine DiSimone, whose marvellous singing of the role of the narrator both bound the Biblical texts together and provided the essential character of the many moods of the work. Hers is a silken vocal quality, sheer and lovely, yet full of drama.",
    Author: "News and Records, Greensboro, NC",
  },
  {
    Title: "Berlioz Nuits d’été with the Augsburg Philharmonic",
    Review: "Mezzo Soprano, Lorraine DiSimone, with her shimmering vibrato and gentle, silvery timbre, has an ideal tone for the French repertoire. She performed these sensitive vignettes with prayerful contemplation and intimate communication as well as sheer joy. We applaud the singer and orchestra for a wonderful performance of a rare cycle.",
    Author: "Augsburg Allgemeine (Germany)",
  },
  {
    Title: "Berlioz’ Romeo and Juliet with the Augsburg Philharmonic",
    Review: "The warm, shimmering Mezzo of Lorraine DiSimone flowed. Ms. DiSimone is a singer who impressively displayed for us last year her special feeling for Berlioz with her performance of the Nuits d’été.",
    Author: "Augsburg Allgemeine (Germany)",
  },
  {
    Title: "Berlioz‘ La Mort de Cléopâtre with the Augsburg Philharmonic",
    Review: "Alternating between intensive and powerful recitative, flowing arias and resigned lyric rapture Lorraine DiSimone was once again proving herself an impressive Berlioz specialist, with her supple mezzo voice in the role of the strongly applauded protagonist.",
    Author: "Augsburg Allgemeine (Germany)",
  },
];

export default ConcertReviews;
