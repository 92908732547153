const StudentAccomplishDataGrads = [
  { item: `Lyndon England accepted into Manhattan School of Music Masters Program` },
  {item: `Lyndon England performed the role of the Shepherd in Tosca with Knoxville Opera`},
  {item: `Amanda Tittle accepted into University of Maryland Masters Program`},
  {item: `Cody Galyon accepted into the AIMS program in Graz, Austria and Georgia State Univ. Masters Program`},
  {item: `Kate Smith accepted into the Harrower Summer Opera Workshop, 2017 Ashlee King accepted into the Harrower Summer Opera Workshop, 2017 Ashlee King accepted into Oberlin in Italy Summer Opera Workshop 2018 Rylee Worstell accepted into Harrower Summer Opera Workshop 2018 Kate Smith accepted into Berlin Opera Academy 2018`},
  {item: `Tori Franklin accepted into Janiec Opera Company of Brevard Music Center 2018, 2019 Ashlee King accepted into Berlin Opera Academy 2019`},
  {item: `Rylee Worstell accepted into L.A. Spotlight Summer Program 2019`},
  {item: `Kate Smith accepted at Carnegie Mellon Masters Program, Fall 2019` },
  {item: `Alex Engle accepted as Principal Artist with Opera Carolina` },
  {item:`Melanie Burbules accepted as Young Artist with Janiec Opera Company of Brevard Music Center, 2016`},
  {item:`Kayla Beard accepted as Young Artist with the Harrower Summer Opera Workshop, 2017`},
]
export default StudentAccomplishDataGrads













