import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import HeroBanner from "../components/HeroBanner/HeroBanner";
import "./Layout.css"
export default function Layout(props) {
  return (
    <div className="layout__conatiner">
      <Header />
      <div className="body__container">
      <HeroBanner />
      <main>{props.children}</main>
      
      </div>
    </div>
  );
}
