import React from "react";
import HomeBio from "../../components/HomeBio/HomeBio";
import "./Home.css";

export default function Home(props) {
 
  return (
    <div className="home__container">
      <div className="home__bio-container">
      <HomeBio homeText={props.homeText} />
      </div>

    </div>
  );
}
