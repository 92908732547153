import React from "react";
import "./Testimonials.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function Testimonials(props) {
  console.log("props.testimonialsData", props.testimonialsData); // Debugging the new prop name

  return (
    <div className="testimonials__container">
      <h1 className="testimonials__title">Testimonials</h1>

      <div className="testimonial-review__carousel-holder">
        {!props.testimonialsData || !Array.isArray(props.testimonialsData) ? (
          <h1>Loading...</h1>
        ) : (
          <>
            {!props.testimonialsData.length ? (
              <h1>Loading...</h1>
            ) : (
              <>
                <Carousel
                  className="review__carousel"
                  showThumbs={false}
                  infiniteLoop={true}
                  showArrows={true}
                  dynamicHeight={true}
                >
                  {props.testimonialsData.map((review, index) => (
                    <div
                      key={index + "testimonial-reviews__container"}
                      className="testimonial-reviews__container"
                    >
                      <h3
                        key={index + review.Testimonial}
                        style={{ fontStyle: "italic" }}
                      >
                        "{review.Testimonial}"
                      </h3>
                      <div
                        key={index + "relative"}
                        style={{ position: "relative", bottom: "25px" }}
                      >
                        <div
                          key={index + "testimonial-split"}
                          className="testimonial-split"
                        >
                          <h3 key={index + review.Name}>{review.Name}</h3>
                          <h4 key={index + review.Title}>{review.Title}</h4>
                        </div>
                        <div
                          key={index + "position"}
                          style={{ position: "relative", bottom: "25px" }}
                        >
                          <h4 key={index + review.Year_Location}>
                            {review.Year_Location}
                          </h4>
                          <h4 key={index + review.Description}>
                            {review.Description}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
