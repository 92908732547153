const Reviews = [
  {
    Opera: "The Crucible",
    Review: "All the elements were in place, and all were praiseworthy. But only at times did they combine to ignite in those waves of power and passion that define opera at its best. Happily those times included the key scenes between John Proctor (James Maddalena) and his wife, Elizabeth (Lorraine DiSimone). The heartbreak of this couple, after John‘s affair with Abigail Williams, filled every look between the two as well as every note they sang, beautifully and with powerful conviction.",
    Author: "The Boston Herald",
  },
  {
    Opera: "Cavalleria Rusticana",
    Review: "Lorraine DiSimone’s Mezzo was warm and vulnerable, portraying the desparate character of Santuzza with emotion and sensitivity.",
    Author: "Augsburg Allgemeine (Germany)",
  },
  {
    Opera: "Der Rosenkavalier",
    Review: "Lorraine DiSimone sang a sensitive, emotional Octavian.",
    Author: "Bayerische Staatszeitung (Germany)",
  },
  {
    Opera: "Die Fledermaus",
    Review: "Lorraine DiSimone was an excellent Count Orlofsky, with a charming Russian accent, faultless French, and a wonderful mezzo voice.",
    Author: "The Idaho Statesman",
  },
  {
    Opera: "La Forza del Destino",
    Review: "Dominating the stage with her beauty, presence, and exciting voice is the sensuous mezzo-soprano Lorraine DiSimone as Preziosilla, a young gypsy. Maestro DeRenzi retains all of her arias and she performs them to perfection, especially the Act 3 'Rataplan'. Brava.",
    Author: "The Venice Gondolier (Florida)",
  },
  {
    Opera: "Nabucco",
    Review: "Lorraine DiSimone sang Fenena with a beautiful mezzo voice.",
    Author: "Opernglas (Germany)",
  },
  {
    Opera: "Ariadne auf Naxos",
    Review: "Lorraine DiSimone was a Composer with expression and vocal power.",
    Author: "Bayerische Staatszeitung (Germany)",
  },
  {
    Opera: "Madama Butterfly",
    Review: "Lorraine DiSimone was excellent. She turned the part of Suzuki, generally a secondary role, into a full main character.",
    Author: "Straubinger Tagblatt (Germany)",
  },
  {
    Opera: "Madama Butterfly",
    Review: "As Butterfly’s maid, Suzuki, Lorraine DiSimone also earned honors with a colorful and rich mezzo voice that captured all the nuances of the devoted and fearful servant and filled the theater with glorious sound.",
    Author: "Sarasota Arts Review",
  },
  {
    Opera: "Romeo and Juliet",
    Review: "A highlight was mezzo-soprano Lorraine DiSimone’s saucy turn as Romeo’s page, Stephano, singing about a dove (Juliette) held prisoner by vultures (the Capulets): 'Que fais-tu, blanche tourterelle?' She got the most enthusiastic applause of the evening.",
    Author: "St. Petersburg Times (Florida)",
  },
  {
    Opera: "The Consul",
    Review: "The cast was uniformly excellent, particular praise merited by Lorraine DiSimone as the regimented but vulnerable Secretary.",
    Author: "Opera News",
  },
  {
    Opera: "Les Contes d’Hoffmann",
    Review: "Lorraine DiSimone has a rich timbered, smooth mezzo-soprano voice that is ideally suited to the role.",
    Author: "Bradenton Herald",
  },
  {
    Opera: "Hansel and Gretel",
    Review: "The success of the Boise Opera production is due in large measure to the remarkable vocal and dramatic talents of Lorraine DiSimone, who plays Hansel, whose loveable and youthful spirit is infectious.",
    Author: "The Idaho Statesman",
  },
];

export default Reviews;
