import React from 'react'
import "../Adjudicating/Adjudicating.css"
import InternationalExpData from '../../../../database/InternationalExpData'

export default function InternationalExp() {
  return (
    <div>
    <h3 className='underline'>International/Intercultural Expertise</h3>
      {InternationalExpData.map((item) => (
        <div className='adj__holder'>
          <div>
            <p className='adj-rep_p'>{item.date}</p>
          </div>
          <div>
            <p className='adj-rep_p'>{item.item}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
