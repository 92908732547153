const UofTPerf = [
  {
    perf:`Faculty Soloist, University Choirs, Beethoven Choral Fantasy Spring 2019`
  },
  {
    perf:`Faculty Soloist, Ready for the World – America. American Art Songs and Duets, Spring 2019`
  },
  {
    perf:`Faculty Soloist, Ready for the World – Poland. Four Polish Songs with Piano; Chopin, Karlowicz, Szymanowski Fall 2016`
  },
  {
    perf:`Faculty Soloist, Soloist and Speaker, “Where East Meets West: An Evening of Opera and Song” with The Confucius Society, UT, Fall 2014`
  },
  {
    perf:`2012-2013 PROFESSIONAL DEVELOPMENT LEAVE IN GERMANY - SABBATICAL`
  },
  {
    perf:`Faculty Soloist, University Men’s Choir Concert, Schubert’s Ständchen, Spring 2012`
  },
  {
    perf:`Faculty Soloist, Duet recital with Guest Baritone, Ronald Ulen, Spring 2012`
  },
  {
    perf:`Faculty Soloist, Faculty Chamber Music Series, Libby Larsen’s Sifting Through the Ruins in commemoration of 9/11/2001, Fall 2011`
  },
  {
    perf:`Faculty Soloist, Narrator, Faculty recital, Kevin Class/Franz Liszt Lecture Recital, Spring 2011`
  },
  {
    perf:`Faculty Soloist, Faculty Chamber Music Series, Spohr Lieder and Wolf Lieder with Clarinet and Wolf Lieder with String Quartet, Knoxville Museum of Art, Spring 2011`
  },
  {
    perf:`Faculty Soloist, Faculty Chamber Music Series, McConville’s La Pioggia nel Pineto, Knoxville Museum of Art, Oct. 17, 2010`
  },
  {
    perf:`Faculty Soloist, Faculty Chamber Music Series, Brahms’ Viola Songs, Cox Auditorium, February 7, 2010`
  },
  {
    perf:`Faculty Soloist, Robert Schumann Celebration Lecture Recital, Schumann’s Frauenliebe und Leben Alumni Memorial Recital Hall, February 2, 2010`
  },
  {
    perf:`Faculty Soloist, Donor Event at Welcome Center for the University of Tennessee School of Music, October 1, 2009`
  },
  {
    perf:`Faculty Soloist, Faculty Chamber Music Series, Bach Duets, Cox Auditorium, September 13, 2009`
  },
  {
    perf:`Faculty Soloist, Gabriel Dobner German Art Song Open Rehearsal Event, November 20, 2008`
  },
  {
    perf:`Faculty Soloist, University of Tennessee School of Music Faculty Group Solo Class, Sept. 2008`
  },
  {
    perf:`Faculty Soloist, Britten’s Abraham and Isaac, University of Tennessee School of Music Faculty Chamber Music Series Concert, Cox Auditorium, September 14, 2008`
  },
  {
    perf:`Faculty Soloist, Brahms’ Liebeslieder Waltzes, University of Tennessee School of Music Faculty Chamber Music Series Concert, Cox Auditorium, February 24, 2008`
  },
  {
    perf:`Guest Faculty Soloist, James Myers Faculty Piano Recital, University of Tennessee Music Hall, April 1, 2007`
  },
  {
    perf:`Faculty Soloist, Tour with the University of Tennessee Symphony Orchestra to Alexandria, VA, Harrisonburg, VA, Kingsport,TN, Feb. 14-16, 2008, Mahler Rückert Lieder excerpt`
  },
  {
    perf:`Faculty Soloist, Frieberger’s Winter Apples (2004), University of Tennessee School of Music Faculty Chamber Music Series Concert, Cox Auditorium, November 2007`
  },
  {
    perf:`Faculty Soloist, Mahler’s Rückert Lieder with the University of Tennessee Symphony Orchestra, Cox Auditorium, November 2007`
  },
  {
    perf:`Faculty Soloist, Guest Artist Gabriel Dobner, Piano, Art Song recital with Marjorie Bennett Stephens, Emily Douglass and Lorraine DiSimone in Alumi Memorial Recital Hall, November 2007, Mahler’s Rückert Lieder`
  },
  {
    perf:`Faculty Soloist with the University of Tennessee Symphony Orchestra and Combined Choirs in Mozart’s Requiem, Cox Auditorium, October 29, 2006`
  },
  {
    perf:`Faculty Soloist, Full Solo Voice Recital, Gabriel Dobner, Piano, University of Tennessee, November 2006`
  },
  {
    perf:`Faculty Soloist, University of Tennessee School of Music Showcase, Club Le Conte, Knoxville, TN, January 14, 2006`
  },
  {
    perf:`Guest Faculty Soloist, Sheila Browne Faculty Viola Recital, Brahms Viola Songs University of Tennessee Music Hall, December 2005`
  },
  {
    perf:`Faculty Soloist with the University of Tennessee Symphony Orchestra and Combined Choirs in Garber’s Job, Cox Auditorium, October 30, 2005`
  },
  {
    perf:`Faculty Soloist, University of Tennessee Faculty Group Solo Class, September 2005`
  },
]
export default UofTPerf