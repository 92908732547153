const OperaCompanyOrch = [
  {
    company:`Bayerischer Rundfunk Kammerorchester (Munich, Germany)`
  },
  { company:`Bel Canto Opera (NYC)`},
  {
    company:`Berkshire Choral Festival (Sheffield, MA)`
  },
  {
    company:`Bloomingdale Chamber Orchestra (NYC)`
  },
  {
    company:`Boston Baroque`
  },
  {
    company:`Boston Classical Orchestra`
  },
  {
    company:`Boston Lyric Opera`
  },
  {
    company:`Boston Modern Orchestra Projects`
  },
  {
    company:`Boston Music Viva`
  },
  {
    company:`Opera Boston`
  },
  {
    company:`Center for Contemporary Opera (NYC)`
  },
  {
    company:`Chamber Opera Theater of New York`
  },
  {
    company:`Chautauqua Opera`
  },
  {
    company:`Chamber Orchestra La Fenice, Italy`
  },
  {
    company:`Cincinnati Chamber Orchestra`
  },
  {
    company:`Composers in Red Sneakers (Boston, MA)`
  },
  {
    company:`Des Moines Metro Opera`
  },
  {
    company:`Dessoff Choir, NYC`
  },
  {
    company:`Da Capo Opera, NYC`
  },
  {
    company:`Glimmerglass Opera`
  },
  {
    company:`Hartford Symphony`
  },
  {
    company:`Knoxville Opera`
  },
  {
    company:`Knoxville Symphony`
  },
  {
    company:`Lincoln Center Outdoors (NYC)`
  },
  {
    company:`Masterworks Chorus (NYC)`
  },
  {
    company:`Mid America Orchestra (Carnegie Hall)`
  },
  {
    company:`New England Lyric Operetta (Stamford, CT)`
  },
  {
    company:`New York Grand Opera (NYC)`
  },
  {
    company:`New York Philharmonic`
  },
  {
    company:`Omaha Symphony`
  },
  {
    company:`Opera on the James (Lynchburg, VA)`
  },
  {
    company:`Opera Idaho`
  },
  {
    company:`Panopticon Chamber Orchestra (NYC)`
  },
  {
    company:`Pittsburgh Symphony`
  },
  {
    company:`Pro Arte Chorale (NYC)`
  },
  {
    company:`Rostock Philharmonic (Rostock, Germany)`
  },
  {
    company:`Sarasota Opera`
  },
  {
    company:`Shreveport Symphony`
  },
  {
    company:`Staatstheater Prague (Czech Republic)`
  },
  {
    company:`Städtische Bühnen Augsburg (Augsburg, Germany) Teatro Lirico Sperimentale (Spoleto, Italy)`
  },
  {
    company:`Texas Opera Theater (Houston, TX)`
  },
  {
    company:`Thüringer Landestheater Orchester (Rudolstadt, Germany) Ulmer Theater (Ulm, Germany)`
  },
  {
    company:`Whitewater Opera (Ohio)`
  },
  {
    company:`Young Audiences of Boston`
  },
]

export default OperaCompanyOrch







































