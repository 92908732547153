const AwardsGrantsData = [
  { item: `May 2012: Alternate for the Fulbright Lecturing/Research Award in Germany for 2012-2013 from the Council for International Exchange of Scholars (CIES).` },
  {item:`May 2012: Awarded a $5000 PDA (Professional Development Award) in support of my Professional Development Leave project “Academic Exchange: American Art Song/German Art Song”. Fall 2012-Spring 2013 Professional Development Leave`},
  {item:`Project: Academic Exchange: American Art Song/German Art Song`},
  {item:`Presented 3 day seminars at the following conservatories in Germany: Detmold Hochschule für Musik`},
  {item:`Darmstadt Akademie für Tonkunst`},
  {item:`Detmold Hochschule für Musik`},
  {item:`Rostock Hochschule für Musik und Theater`},
  {item:`Hamburg Hochschule für Musik`},
  {item:`Hochschule für Musik Hanns Eisler - Berlin Hamburg Hochschule für Musik`},
  {item:`The goal of this project was to introduce American art song to the students and professors in German academia and to gather information and repertoire about modern German art song which is unknown to the American academic world. Articles and publications as a result of this study will be forthcoming in the next year.`},
  {item:`Fall 2009: SARIF Grant of $500 from the Office of Research at the University of TN to fund the premiere concert of Brendan McConville’s piece “La Pioggia nel Pineto” for Voice, Percussion and Piano at the “Estate Musicale 2010” Summer Festival 2010, in Roseto degli’Abruzzi, Italy on August 4, 2010.`},
]
export default AwardsGrantsData







