import React from 'react'
import AwardsGrantsData from "../../../../database/AwardsGrantsData"

export default function AwardsGrants() {
  return (
    <div>
    <h3 className='underline'>Awards and Grants</h3>
    {AwardsGrantsData.map((item) => (
      <>
        <p>{item.item}</p>
      </>
  ))}
    </div>
  )
}
