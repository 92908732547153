import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function Reviews(props) {
  return (
    <div>
    <div style={{position:'relative', top:'20px'}}>
    <h1 className="testimonials__title">Opera Reviews</h1>
    </div>

    <div className="opera-review__carousel-holder">
      
    {!props.operaReviews ? (
      <h1>Loading...</h1>
    ) : (
      <>
        {!props.operaReviews.length ? (
          <h1>Loading...</h1>
        ) : (
          <>
            <Carousel
              className="review__carousel"
              showThumbs={false}
              infiniteLoop={true}
              showArrows={true}
            >
              {props.operaReviews.map((review, index) => (
                <div key={index + "review-div"} className="opera-review__container">
                  <h1 key={index + review.Opera}>{review.Opera}</h1>
                  <h3
                    key={index + "opera-review"}
                    style={{ fontStyle: "italic", paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    "{review.Review}"
                  </h3>
                  <h4 key={index + review.Author}>- {review.Author}</h4>
                </div>
              ))}
            </Carousel>
          </>
        )}
      </>
    )}
    
    </div>
    <h1 className="testimonials__title">Concert Reviews</h1>

    <div className="concert-review__carousel-holder">
    {!props.concertReviews ? (
      <h1>Loading...</h1>
    ) : (
      <>
        {!props.concertReviews.length ? (
          <h1>Loading...</h1>
        ) : (
          <>
            <Carousel
              className="concert-review__carousel"
              showThumbs={false}
              infiniteLoop={true}
              showArrows={true}
            >
              {props.concertReviews.map((review, index) => (
                <div key={index + "concert"} className="reviews__container">
                  <h2 key={index + review.Title}>{review.Title}</h2>
                  <h3
                    key={index + "concert-review"}
                    style={{ fontStyle: "italic" }}
                  >
                    "{review.Review}"
                  </h3>
                  <h4 key={index + review.Author}>- {review.Author}</h4>
                </div>
              ))}
            </Carousel>
          </>
        )}
      </>
    )}
    
    </div>
    </div>
  )
}
