const MembershipServiceData = [
  {
    item: `Member: NATS (National Association of Teachers of Singing), 2003-2020`
  },
  {
    item: `NATS: Chaired NATS Committee on Competition/Accompanist Regulations in Fall 2007`
  },
  {
    item: `Member: College Music Society, 1992-2015`
  },
  {
    item: `College Music Society: Presented a Poster Session at the College Music Society Regional Conference in Orlando, FL in February 2009, “The Wall of Singing Axioms” analyzed the learning styles and key themes in vocal study.`
  },
  {
    item: `Feb. 2011: 32nd Southern Regional Conference of the College Music Society at Rhodes College, Memphis, TN: Performed Brendan McConville’s “La Pioggia nel Pineto” for Voice, Percussion and Piano. Submission by the composer.`
  },
  {
    item: `Performed in the Rossini Festival Italian Street Fair in 2006, 2010, 2011 Knoxville, TN`
  },
  {
    item: `Member: Knoxville Opera Guild, 2009-2012`
  },
]
export default MembershipServiceData