import React, { useState, useEffect } from "react";
import "./MobileNav.css";
import { useNavigate } from "react-router-dom";

export default function MobileNav() {
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
  const [navMenu, openedNavMenu] = useState("mobile-nav__closed");
  const [menuText, setMenuText] = useState("MENU")

  const toggleClick = () => {
    if (toggle === false) {
      setToggle(true);
      setMenuText("X")
    } else {
      setToggle(false);
      setMenuText("MENU")
    }
  };

  useEffect(() => {
    if (toggle === true) {
      openedNavMenu("mobile-nav__opened");
    } else {
      openedNavMenu("mobile-nav__closed");
    }
  }, [toggle]);

  return (
    <div className="mobile-nav__container">
      <div className="mobile-nav__button">
        <p onClick={toggleClick}>{menuText }</p>
      </div>
      <div className={navMenu}>
        <p
          onClick={() => {
            navigate("/");
            setToggle(false);
            setMenuText("MENU")
          }}
        >
          Home
        </p>

        <p
          onClick={() => {
            navigate("/about");
            setToggle(false);
            setMenuText("MENU")
          }}
        >
          About
        </p>

        <p
          onClick={() => {
            navigate("/media");
            setToggle(false);
            setMenuText("MENU")
          }}
        >
          Media
        </p>

        <p
          onClick={() => {
            navigate("/teaching-philosophy");
            setToggle(false);
            setMenuText("MENU")
          }}
        >
          Teaching Philosophy
        </p>

        <p
          onClick={() => {
            navigate("/testimonials");
            setToggle(false);
            setMenuText("MENU")
          }}
        >
          Testimonials
        </p>

        <p
        onClick={() => {
          navigate("/reviews");
          setToggle(false);
          setMenuText("MENU")
        }}
      >
        Reviews
      </p>

        <p
          onClick={() => {
            navigate("/contact");
            setToggle(false);
            setMenuText("MENU")
          }}
        >
          Contact
        </p>
      </div>
    </div>
  );
}
