import React from "react";
import "./Footer.css";

import Facebook from "../../assets/Facebook Cutout.png";
import LinkedIn from "../../assets/linkedin-logo-512x512.png";

export default function Footer() {
  return (
    <div className="footer__container">
      <a
        href="https://www.linkedin.com/in/lorraine-disimone-a8b589b/"
        target="_blank"
        rel="noreferrer"
      >
        <img className="social-icon" src={LinkedIn} alt="LinkedIn" />
      </a>
    </div>
  );
}

// <a
//         href="https://www.facebook.com/lorraine.disimone"
//         target="_blank"
//         rel="noreferrer"
//       >
//         <img className="social-icon" src={Facebook} alt="facebook" />
//       </a>