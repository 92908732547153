import Composer1 from "../assets/ImageCarousel/Composer_IMG_7300_nkzfd4.jpg"
import Queen from "../assets/ImageCarousel/Queen_IMG_7419_ockdpx.jpg"
import Octavian from "../assets/ImageCarousel/Octavian_IMG_7304_dsf1dt.jpg"
import Elizabeth from "../assets/ImageCarousel/Elizabeth_IMG_7307_lslzzc.jpg"
import Composer2 from "../assets/ImageCarousel/Composer_2_IMG_7306_kl6uxa.jpg"
import Nancy from "../assets/ImageCarousel/Nancy_IMG_7302_tq8nya.jpg"
import Octavian2 from "../assets/ImageCarousel/Octavian_2_IMG_7303_vwfh06.jpg"
import Wellgunde from "../assets/ImageCarousel/Wellgunde_IMG_7301_oacxoc.jpg"
import Octavian3 from "../assets/ImageCarousel/Octavian_3_IMG_7417_tcyt9u.jpg"
import Preziosilla from "../assets/ImageCarousel/Preziosilla_IMG_7420_jjketg.jpg"


const MediaImageCarousel = [
  {
    Name: `Composer in "Ariadne auf Naxos"`,
    Attachments: Composer1,
  },
  {
    Name: `Queen in "Full Moon in March"`,
    Attachments: Queen,
  },
  {
    Name: `Octavian in "Der Rosenkavalier"`,
    Attachments: Octavian,
  },
  {
    Name: `Elizabeth Proctor in "The Crucible"`,
    Attachments: Elizabeth,
  },
  {
    Name: `Composer in "Ariadne auf Naxos"`,
    Attachments: Composer2,
  },
  {
    Name: `Nancy in "Albert Herring"`,
    Attachments: Nancy,
  },
  {
    Name: `Octavian in "Der Rosenkavalier"`,
    Attachments: Octavian2,
  },
  {
    Name: `Wellgunde in "Götterdämmerung"`,
    Attachments: Wellgunde,
  },
  {
    Name: `Octavian in "Der Rosenkavalier"`,
    Attachments: Octavian3,
  },
  {
    Name: `Preziosilla in "La Forza del Destino"`,
    Attachments: Preziosilla,
  },
]

export default MediaImageCarousel