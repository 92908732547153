import React from 'react'
import "./ConcertRep.css"
import ConcertRepList from '../../../../database/ConcertRep'

export default function ConcertRep() {
  return (
    <div className='concert-rep__container'>
    <h3 className='underline'>Concert Repertoire Performed</h3>
      {ConcertRepList.map((rep) => (
        <div className='concert-rep__holder'>
          <div>
            <p className='concert-rep_p'>{rep.piece }</p>
          </div>
          <div>
            <p className='concert-rep_p'>{rep.composer }</p>
          </div>
        </div>
    ))}
    </div>
  )
}
