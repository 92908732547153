import React from 'react'
import StudentAccomplishDataGrads from '../../../../database/StudentAccomplishGradsData'
import StudentAccomplishDataUnderGrads from '../../../../database/StudentAccomplishUnderGradsData'
export default function StudentAccomp() {
  return (
    <div>
      <h3 className='underline'>Student Accomplishments</h3>
      <h5 className='underline'>Undergraduates</h5>
    {StudentAccomplishDataUnderGrads.map((item) => (
      <>
        <p>{item.item}</p>
      </>
    ))}
    <h5 className='underline'>Graduates</h5>
    {StudentAccomplishDataGrads.map((item) => (
      <>
        <p>{item.item}</p>
      </>
  ))}
    </div>
  )
}
