import React from 'react'
import "./OperaRep.css"
import RolesPerformed from "../../../../database/rolesPerformed.js"



export default function OperaRep() {
  
  return (
    <div>
      <h3 className='underline'>Opera Repertoire Performed</h3>
      <div>
        {RolesPerformed.map((role, index) => (
          <div key={index+'opera-rep__holder'} className='opera-rep__holder'>
          <div>
              <p key={index+'opera-roles_p'} className='opera-roles_p'>{role.role}</p>
        </div>
        <div key={index+'opera-roles'} className='opera-roles'>
              <p key={index+'opera-roles_p'} className='opera-roles_p'>{ role.opera }</p>
        </div>
        <div key={index+'opera-roles_p'}>
              <p key={index+role.composer} className='opera-roles_p'>{role.composer}</p>
        </div>
          </div>
        ))}
       
      </div>
    </div>
  )
}
