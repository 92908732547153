import React from 'react'
import "./Adjudicating.css"
import AdjudicatingDatabase from '../../../../database/Adjudicating'

export default function Adjudicating() {
  return (
    <div className='adjudicating__container'>
      <h3 className='underline'>Adjudicating</h3>
      {AdjudicatingDatabase.map((item) => (
        <div className='adj__holder'>
          <div>
            <p className='adj-rep_p'>{item.date}</p>
          </div>
          <div>
            <p className='adj-rep_p'>{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
