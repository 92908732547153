const TestimonialsData = [
  {
    Testimonial: "Lorraine is a wonderfully skilled and intuitive pedagogue! Her approach to technique, musicality, and language helped to transform my singing at a time when I was going through some major vocal shifts. She combines a sound understanding of vocal anatomy and physiology with a flexible and encouraging teaching style. My time studying under her was a period of tremendous vocal growth, freedom, and joy. Lorraine is a musician and teacher of the highest caliber and I cannot recommend her highly enough.",
    Name: "Blayne Ziegenfuss",
    Title: "Tenor and Voice Teacher",
    Year_Location: "Fall 2022 DMA at LSU",
    Description: "Private student",
  },
  {
    Testimonial: "Lorraine DiSimone is a wonderful teacher who always puts her care for her students first. As a mentor, she is able to offer advice from her experience and support for her students’ needs. As a teacher, she has the tools and communication skills to help her students make new discoveries and consistently progress in their journey. I loved working with Ms. DiSimone and I became a more joyous, autonomous and skilled singer with her guidance.",
    Name: "Karen Wemhoener",
    Title: "Soprano",
    Year_Location: "Fall 2022 MM at Boston Conservatory",
    Description: "Studied first 2 years in undergrad at University of TN Knoxville (before my retirement)",
  },
  {
    Testimonial: "Ms. DiSimone is not only an outstanding teacher of voice but she also imparts on her students how to be professional and knowledgeable musicians. Her expertise of musicology and operatic repertoire for all uplifts her students to higher levels of achievement and understanding of music literature. Most importantly, though, is that Ms. DiSimone cares deeply for her students, whether it be their progress or future, with great compassion to each student’s individuality.",
    Name: "Ashlee King",
    Title: "Soprano",
    Year_Location: "Fall 2022 MM Eastman School of Music",
    Description: "Studied 4 years with me for her BM at University of TN Knoxville",
  },
  {
    Testimonial: "I studied under the teaching of Lorraine DiSimone for four and a half years, through my undergraduate career and a few months beyond, before moving to a new city. Lorraine was the first to guide my vocal and artistic development. She laid the groundwork that I have built upon through my graduate studies and the beginnings of my work as a professional singer. I have benefited from her teaching in a variety of ways and recommend her guidance to anyone looking for a pedagogically-sound teacher with a holistic approach who knows what it takes to create artistic magnetism in performance.",
    Name: "Kate Smith",
    Title: "Soprano",
    Year_Location: "MM 2021 Carnegie Mellon University",
    Description: "4 years BM at University of TN Knoxville",
  },
  {
    Testimonial: "I had studied with Lorraine years ago and always enjoyed it. But it was wonderful to experience recently how much her teaching has evolved and how she has incorporated so many truly useful and effective exercises that she has come across through her own extensive study and performance around the U.S. and Europe. Her emphasis on relaxation and “natural” vocal and breathing technique definitely put me at my ease, and each exercise helped me to get a more open sound and to always feel like I had enough breath to support longer lines. Her suggestions on phrasing, text and acting were also spot on.",
    Name: "Robert Shampain",
    Title: "Baritone",
    Year_Location: "Executive Director BAYFEST Youth Theatre Program",
    Description: "Private Student",
  },
  {
    Testimonial: "Lorraine DiSimone was my primary voice teacher while I pursued a graduate degree at the University of Tennessee. During those 2 years I was able to truly uncover the power of my instrument thanks to the instruction of Ms. DiSimone. I will never forget realizing how much I had grown just within the first semester! Lorraine brought out my actual voice and I’ll never be able to thank her enough for that!",
    Name: "Tori Patricia Franklin",
    Title: "Mezzo who transitioned to Soprano",
    Year_Location: "3 years Sarasota Opera Apprentice, 2 years Brevard Young Artist Janiec Opera Company",
    Description: "Marble City Opera Soloist, Knoxville Opera Soloist",
  },
  {
    Testimonial: "Lorraine DiSimone understands two things very well - how the voice works best - both in health and sound; and how to communicate that to actors in a most entertaining and understandable way. I heartily recommend her to all voice actors in any genre.",
    Name: "Johnny Heller",
    Title: "Audiofile Golden Voice Narrator, Audiobook and Commercial VO coach",
    Year_Location: "ALA Odyssey Award winner, Grammy nominee",
    Description: "30 Earphone Awards, 14-time Audie Award Nominee and 4-time Audie Award Winner",
  },
];

export default TestimonialsData;
