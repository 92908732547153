import Verlassene from "../assets/Recordings/01 Verlassene Mädglein.m4a"
import Va from "../assets/Recordings/04 Va, Laisse Couler Mes Larmes.m4a"
import Flute from "../assets/Recordings/06 La Flûte De Pan.m4a"
import Chevelure from "../assets/Recordings/07 Le Chevelure.m4a"
import Tombeau from "../assets/Recordings/08 Le Tombeau Des Naïades.m4a"
import accostare from "../assets/Recordings/05NonTaccostareAllUrna.m4a"
import Asie from "../assets/Recordings/09 Asie.m4a"
import Chantèe from "../assets/Recordings/10 La Flûte En Chantèe.m4a"
import indifferent from "../assets/Recordings/11 L-indifferent.m4a"

const Songs = [
  {
    Name: "Das Verlassene Mägdlein",
    Description: "",
    Attachments: Verlassene,
    Accompanist: "Ann Listokin, Piano",
    Composer: "Wolf",
  },
  {
    Name: "Va, Laisse Couler Mes Larmes",
    Description: "",
    Attachments: Va,
    Accompanist: "Ann Listokin, Piano",
    Composer: "Massenet",
  },
  {
    Name: "La Flûte De Pan",
    Description: "Chansons De Bilitis",
    Attachments: Flute,
    Accompanist: "Gabriel Dobner, Piano",
    Composer: "Debussy",
},
{
  Name: "Le Chevelure",
  Description: "Chansons De Bilitis",
  Attachments: Chevelure,
  Accompanist: "Gabriel Dobner, Piano",
  Composer: "Debussy",
},
{
  Name: "Le Tombeau des Naïades",
  Description: "Chansons De Bilitis",
  Attachments: Tombeau,
  Accompanist: "Gabriel Dobner, Piano",
  Composer: "Debussy",
},
{
  Name: "Non t'accostare all'urna",
  Description: "",
  Attachments: accostare,
  Accompanist: "Gabriel Dobner, Piano",
  Composer: "Verdi",
},
{
  Name: "Asie Schéhérazade",
  Description: "Schéhérazade",
  Attachments: Asie,
  Accompanist: "Augsburg Philharmonic",
  Composer: "Ravel",
},
{
  Name: "La Flûte En Chantèe Schéhérazade",
  Description: "Schéhérazade",
  Attachments: Chantèe,
  Accompanist: "Augsburg Philharmonic",
  Composer: "Ravel",
},
{
  Name: "L'indifferent Schéhérazade",
  Description: "Schéhérazade",
  Attachments: indifferent,
  Accompanist: "Augsburg Philharmonic",
  Composer: "Ravel",
},

]
export default Songs