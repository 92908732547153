const RolesPerformed = [
  {
    role: "Viola",
    opera: "Twelfth Night",
    composer:"Amram",
  },
  {
    role: "Sally",
    opera: "Hand of Bridge",
    composer:"Barber",
  },
  {
    role: "Henrichetta",
    opera: "I Puritani",
    composer:"Bellini",
  },
  {
    role: "Nancy",
    opera: "Albert Herring",
    composer:"Britten",
  },
  {
    role: "Hippolyta",
    opera: "A Midsummer Night‘s Dream",
    composer:"Britten",
  },
  {
    role: "Carmela",
    opera: "La Vida Breve",
    composer:"DeFalla",
  },
  {
    role: "Huguette",
    opera: "The Vagabond King",
    composer:"Friml",
  },
  {
    role: "Buttercup",
    opera: "H.M.S. Pinafore",
    composer:"Gilbert & Sullivan",
  },
  {
    role: "Narcissus",
    opera: "Agrippina",
    composer:"Händel",
  },
  {
    role: "The Queen",
    opera: "Full Moon in March",
    composer:"Harbison",
  },
  {
    role: "Savitri",
    opera: "Savitri",
    composer:"Holst",
  },
  {
    role: "Hansel/Mother",
    opera: "Hansel and Gretel",
    composer:"Humperdinck",
  },
  {
    role: "Santuzza",
    opera: "Cavalleria Rusticana",
    composer:"Mascagni",
  },
  {
    role: "Mamma Rosa",
    opera: "Silvano",
    composer:"Mascagni",
  },
  {
    role: "Fiamina",
    opera: "Griselidis",
    composer:"Massenet",
  },
  {
    role: "The Secretary",
    opera: "The Consul",
    composer:"Menotti",
  },
  {
    role: "Sesto",
    opera: "La Clemenza di Tito",
    composer:"Mozart",
  },
  {
    role: "Cherubino",
    opera: "Le Nozze di Figaro",
    composer:"Mozart",
  },
  {
    role: "Marcellina",
    opera: "Le Nozze di Figaro",
    composer:"Mozart",
  },
  {
    role: "2nd Lady",
    opera: "The Magic Flute",
    composer:"Mozart",
  },
  {
    role: "Nicklausse",
    opera: "Les Contes d‘Hoffmann",
    composer:"Offenbach",
  },
  {
    role: "Suzuki",
    opera: "Madama Butterfly",
    composer:"Puccini",
  },
  {
    role: "Zelatrice",
    opera: "Suor Angelica",
    composer:"Puccini",
  },
  {
    role: "Principessa",
    opera: "Suor Angelica",
    composer:"Puccini",
  },
  {
    role: "Dido",
    opera: "Dido & Aeneas",
    composer:"Purcell",
  },
  {
    role: "Concepcion",
    opera: `L’Heure Espagnole`,
    composer:"Ravel",
  },
  {
    role: "Clementina",
    opera: "The Desert Song",
    composer:"Romberg",
  },
  {
    role: "Rosina",
    opera: "Il Barbiere di Siviglia",
    composer:"Rossini",
  },
  {
    role: "Angelina",
    opera: "Cenerentola",
    composer:"Rossini",
  },
  {
    role: "Malcolm/Albina",
    opera: "La Donna del Lago",
    composer:"Rossini",
  },
  {
    role: "Modestina",
    opera: "Il Viaggio a Reims",
    composer:"Rossini",
  },
  {
    role: "Hata",
    opera: "The Bartered Bride",
    composer:"Smetana",
  },
  {
    role: "Estrelda",
    opera: "El Capitan",
    composer:"Sousa",
  },
  {
    role: "Orlofsky",
    opera: "Die Fledermaus",
    composer:"J. Strauß",
  },
  {
    role: "Mirabella",
    opera: "Der Zigeunerbaron",
    composer:"J. Strauß",
  },
  {
    role: "III Magd",
    opera: "Elektra",
    composer:"R. Strauss",
  },
  {
    role: "Composer",
    opera: "Ariadne auf Naxos",
    composer:"R. Strauss",
  },
  {
    role: "Octavian",
    opera: "Der Rosenkavalier",
    composer:"R. Strauss",
  },
  {
    role: "Woman",
    opera: "Dream Play (World Premiere)",
    composer:"Sullivan",
  },
  {
    role: "Laura",
    opera: "Iolanthe",
    composer:"Tchaikovsky",
  },
  {
    role: "Larina",
    opera: "Eugene Onegin",
    composer:"Tchaikovsky",
  },
  {
    role: "Meg Page",
    opera: "Falstaff",
    composer:"Verdi",
  },
  {
    role: "Dame Quickly",
    opera: "Falstaff",
    composer:"Verdi",
  },
  {
    role: "Preziosilla",
    opera: "La Forza del destino",
    composer:"Verdi",
  },
  {
    role: "Fenena",
    opera: "Nabucco",
    composer:"Verdi",
  },
  {
    role: "Maddalena",
    opera: "Rigoletto",
    composer:"Verdi",
  },
  {
    role: "Wellgunde",
    opera: "Das Rheingold",
    composer:"Wagner",
  },
  {
    role: "Gerhilde",
    opera: "Die Walküre",
    composer:"Wagner",
  },
  {
    role: "Wellgunde",
    opera: "Götterdämmerung",
    composer:"Wagner",
  },
  {
    role: "Elizabeth Proctor",
    opera: "The Crucible",
    composer:"Ward, Robert",
  },
]
export default RolesPerformed