import React from 'react'
import "../Adjudicating/Adjudicating.css"

import MassterclassData from '../../../../database/MasterclassData'

export default function MasterClass() {
  return (
    <div>
    <h3 className='underline'>Master Classes</h3>
      {MassterclassData.map((item) => (
        <div className='adj__holder'>
          <div>
            <p className='adj-rep_p'>{item.date}</p>
          </div>
          <div>
            <p className='adj-rep_p'>{item.item}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
