
const StudentAccomplishDataUnderGrads = [
  { item: `Corinne Stevens accepted for Post Graduate work at the University of Oklahoma Linda Brimer accepted into the Merola Young Artist Program` },
  {item: `Sara Kennedy accepted into the Sarasota Opera Young Artist Program`},
  {item: `Candra Savage accepted into the Sarasota Opera Young Artist Program 2 seasons`},
  {item: `Candra Savage accepted into the Opera North Young Artist Program`},
  {item: `Leah Serr accepted into the Sarasota Opera Young Artist Program 3 seasons`},
  {item: `Leah Serr accepted into the Des Moines Metro Young Artist Program 2 seasons`},
  {item: `Leah Serr performed the role of Nicklausse in Tales of Hoffmann with Knoxville Opera`},
]
export default StudentAccomplishDataUnderGrads
