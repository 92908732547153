import React from "react";
import MobileNav from "../MobileNav/MobileNav";
import NavBar from "../NavBar/NavBar";
import "./Header.css";

export default function Header() {
  return (
    <div className="header__container">
      <div className="header-nav__desktop">
        <NavBar />
      </div>
      <div className="header-nav__mobile">
        <MobileNav />
      </div>
    </div>
  );
}
