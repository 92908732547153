import React from "react";
import Footer from "../../components/Footer/Footer";
import "./Contact.css";


export default function Contact() {
  return (
    <div className="contact__container">
      <h1 className="contact__title">Contact</h1>
      <div className="contact-info__container">
      <p className="contact__text">Lorraine maintains an active teaching studio in New York City. To schedule a lesson in person or digitally, or if you have questions please Call or email from the links below.</p>
      </div>
      <div className="contact__email-btn-holder">
        <p>Phone: +1-865-599-7203</p>
        <br />
        <br />
        <a
          href="mailto:lorrainedisimone@gmail.com"
          target="_blank"
          rel="noreferrer"
          role="button"
          className="contact__email-btn"
        >
          Email Me
        </a>
      </div>
      <Footer />
    </div>
  );
}
// <button className="contact__send-btn">Send</button>
