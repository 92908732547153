const MassterclassData = [
  {
    date: `2022, 2023`,
    item:`Opera on the James Tyler Young Artists`
  },
  {
    date: `2019,2018`,
    item:`AIMS – American Institute of Musical Studies, Graz, Austria 2016 Berlin Opera Academy, Berlin, Germany`
  },
  {
    date: `2015`,
    item:`Seymour High School, Seymour, TN`
  },
  {
    date: `2013`,
    item:`Karns High School, Powell, TN`
  },
  {
    date: `2013`,
    item:`Darmstadt Akademie für Tonkunst, American Art Song Lecture and Master Class`
  },
  {
    date: `2013`,
    item:` Rostock Hochschule für Musik und Theater; American Art Song Lecture and Master Class 2013 Hochschule für Musik Hanns Eisler/Berlin; American Art Song Lecture and Master Class 2012 Hamburg Hochschule für Musik; American Art Song Lecture`
  },
  {
    date: `2012`,
    item:`Detmold Hochschule für Musik; American Art Song Lecture and Master Class`
  },
  {
    date: `2010`,
    item:`Centenary College, Shreveport, LA`
  },
  {
    date: `2010`,
    item:`University of Wisconsin at Milwaukee`
  },
  {
    date: `2009`,
    item:`University of Massachusetts at Lowell: Master Class and American Art Song Lecture`
  },
  {
    date: `2008`,
    item:`Detmold Conservatory of Music, Detmold, Germany`
  },
  {
    date: `2008`,
    item:`Salem College, Winston, Salem, North Carolina: Master Class and Duet Recital`
  },
  {
    date: `2008`,
    item:` James Madison Opera/Art Song Summer Festival in Chemnitz, Germany`
  },
  {
    date: `2007`,
    item:`University of Mississippi at Oxford: Master Class and Full Recital`
  },
  {
    date: `2007`,
    item:`Union University at Jackson, Tennessee: Master Class and Full Recital`
  },
  {
    date: `2005`,
    item:` James Madison University, Harrisonburg, Virginia`
  },
  {
    date: `2004`,
    item:`North Carolina School of the Arts, Winston-Salem, NC`
  },
  {
    date: `2004`,
    item:`New England Conservatory Extension Division Opera Workshop, Boston, MA`
  },
]
export default MassterclassData

 
 
 
 

 
 
 
 
 
 

 
 

 
 