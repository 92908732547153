import React from "react";
import "./NavBar.css";
import { useNavigate } from "react-router-dom";

export default function NavBar() {
  const navigate = useNavigate();

  return (
    <div className="nav__container">
      <p
        onClick={() => {
          navigate("/");
        }}
      >
        Home
      </p>

      <p
        onClick={() => {
          navigate("/about");
        }}
      >
        About
      </p>

      <p
        onClick={() => {
          navigate("/media");
        }}
      >
        Media
      </p>

      <p
        onClick={() => {
          navigate("/teaching-philosophy");
        }}
      >
        Teaching Philosophy
      </p>
     
      <p
        onClick={() => {
          navigate("/testimonials");
        }}
      >
        Testimonials
      </p>
      <p
      onClick={() => {
        navigate("/reviews");
      }}
    >
      Reviews
      </p>
      <p
      onClick={() => {
        navigate("/resume");
      }}
    >
      Resume
    </p>
      <p
        onClick={() => {
          navigate("/contact");
        }}
      >
        Contact
      </p>
    </div>
  );
}
