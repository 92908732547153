import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../screens/Home/Home";
import About from "../screens/About/About";
import Contact from "../screens/Contact/Contact";
import Testimonials from "../screens/Testimonials/Testimonials";
import Media from "../screens/Media/Media";
import TeachingPhilosophy from "../screens/TeachingPhilosophy/TeachingPhilosophy";
import Reviews from "../screens/Reviews/Reviews";
import Resume from "../screens/Resume/Resume";

export default function MainContainer(props) {
  return (
    <Routes>
      <Route path="/" element={<Home homeText={props.homeText} />} />
      <Route path="/about" element={<About cds={props.cds } aboutParagraphs={props.aboutParagraphs} aboutPhotos={props.aboutPhotos} />} />
      <Route path="/media" element={<Media songs={props.songs} mediaCarouselPics={props.mediaCarouselPics} mediaYoutubeLinks={props.mediaYoutubeLinks} />} />
      <Route path="/teaching-philosophy" element={<TeachingPhilosophy teachPhilText={props.teachPhilText} />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/reviews" element={<Reviews operaReviews={props.operaReviews} concertReviews={props.concertReviews} testimonials={props.testimonials} />} />
      <Route path="/testimonials" element={<Testimonials operaReviews={props.operaReviews} concertReviews={props.concertReviews} testimonialsData={props.testimonialsData} />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}
