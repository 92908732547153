import React from 'react'
import UofTPerf from '../../../../database/UofTPerf'

export default function PerfUofT() {
  return (
    <div>
    <h3 className='underline'>Performances at the University of Tennessee 2005-2019</h3>
    {UofTPerf.map((perf) => (
      <>
        <p>{perf.perf}</p>
      </>
  ))}
    </div>
  )
}
