const InternationalExpData = [
  {
    date:`July 2018,2019`,
    item: `Guest Faculty at AIMS – American Insitute of Musical Studies in Graz, Austria`
  },
  {
    date:`July-August 2018`,
    item: `Faculty, Berlin Opera Academy, Berlin, Germany`
  },
  {
    date:`June-August2017`,
    item: `Faculty, Berlin Opera Academy, Berlin, GermanyIntensive international summer opera academy; 125 young singers performing in opera and concert; Voice teacher, role preparation, concert preparation`
  },
  {
    date:`July 2016`,
    item: `Master Class Guest Teacher, Berlin Opera Academy, Berlin Germany`
  },
  {
    date:`December 2012`,
    item: `English language coaching for soloist and chorus of Volkstheater Rostock in preparation for world`
  },
  {
    date:`January 2013`,
    item: `premiere of “Happy Birthday, Mr. President” by British composer, Kriss Russmann.`
  },
  {
    date:`July 2010,2011`,
    item: `Faculty, Amalfi Coast Music Festival - Vietri sul mare, Italy`
  },
  {
    date:`July 2014`,
    item: `Voice teacher for summer opera festival encompassing two weeks of concerts, recitals, opera scenes and three performances of a full opera with orchestra. 2014: Stage Director “Suor Angelica”`
  },
  {
    date:`July 2009`,
    item: `Volkstheater Rostock - Rostock, Germany: Guest French Coach. Instructed and coached international singers on French diction and pronunciation for upcoming production of “L’amour de Loin” by Saariaho.`
  },
  {
    date:`June 2009`,
    item: `Guest Lecturer (presented a lecture on American Art Song (in German) and Master Class (conducted in German) at the Hochschule für Musik,Detmold Detmold, Germany`
  },
  {
    date:`July 2008`,
    item: `Guest Faculty, James Madison University Summer Festival, Chemnitz, Germany, Master Class and Voice lessons.`
  },
  {
    date:`2000-2005`,
    item: `Lecturer and performer for seminars conducted by The International Forum. The International Forum originated through the Wharton School of Business and evolved as a producer of business forums to educate CEOs and managers worldwide about the cultural history and economic globalization of expanding markets in Europe, Asia and the United States. A secondary program grew out of this original program and was entitled “Leadership through Music” in which I coached public speaking and leadership qualities through the lens of music and opera. We produced Seminars in Stockholm, Sweden, Riga, Latvia, Krakow, Poland, Philadelphia, PA.`
  },

]
export default InternationalExpData