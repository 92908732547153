import React from "react";
import "./Media.css";
import Songs from "../../database/Audio"

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";

export default function Media(props) {
  // console.log("Songs",Songs)
  return (
    <div className="media__container">
      <h1 className="media-page__title">Recordings</h1>
      <AudioPlayer
        songs={Songs}
      />

      <div className="media__youtube-holder">
      <iframe
className="media__youtube-video"
width="560"
height="315"
src="https://www.youtube.com/embed/XvYADFUyyG8"
title="YouTube video player"
frameBorder="0"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
        />  
        <iframe
className="media__youtube-video"
width="560"
height="315"
src="https://www.youtube.com/embed/q_z8paE7lPE"
title="YouTube video player"
frameBorder="0"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
/>  
      </div>
      <h1 className="media-page__title">Images</h1>
      <div className="media__carousel-holder">
      {!props.mediaCarouselPics ? (
        <h1>Loading...</h1>
      ) : (
        <>
          {!props.mediaCarouselPics.length ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <Carousel
                className="media__carousel"
                showThumbs={false}
                infiniteLoop={true}
                showIndicators={false}
                showArrows={true}
              >
                {props.mediaCarouselPics.map((pic, index) => (
                  <div key={index + "pic"}>
                    <img
                      key={index + "media__carousel-pic"}
                      alt={pic.Name} // Use the Name as the alt text
                      className="media__carousel-pic"
                      src={pic.Attachments} // Directly use Attachments as the image source
                    />
                    <p key={index + pic.Name}>{pic.Name}</p> {/* Directly use pic.Name */}
                  </div>
                ))}
              </Carousel>
            </>
          )}
        </>
      )}
    </div>
    
    </div>
  );
}
