import React from 'react'
import "./Resume.css"
import Adjudicating from './ResumeComponents/Adjudicating/Adjudicating'
import AwardsGrants from './ResumeComponents/AwardsGrants/AwardsGrants'
import CompaniesPerformed from './ResumeComponents/CompaniesPerformed/CompaniesPerformed'
import ConcertRep from './ResumeComponents/ConcertRep/ConcertRep'
import InternationalExp from './ResumeComponents/InternationalExp/InternationalExp'
import MasterClass from './ResumeComponents/MasterClass/MasterClass'
import MembershipService from './ResumeComponents/MembershipService/MembershipService'
import OperaRep from './ResumeComponents/OperaRep/OperaRep'
import PerfUofT from './ResumeComponents/PerfUofT/PerfUofT'
import StudentAccomp from './ResumeComponents/StudentAccomp/StudentAccomp'


export default function Resume() {
   
  return (
    <div id="top" className='resume__container'>
      <div className='resume'>
        <h3 className='underline'>Education and Professional Training</h3>
        <p>1982 Master of Music; Vocal Performance, New England Conservatory of Music, Boston, MA
          <br />
          1978 Bachelor of Music; Music History, cum laude, University of Connecticut, Storrs, CT
          <br />
          2017 TN NATS Chapter Musical Theatre Workshop with Jan McDaniel in Murfreesboro, TN
          <br />
          2016 Voice Foundation National Conference; Philadelphia, PA
          <br />
          2014 David Jones Master Classes, Berlin, Germany
          <br />
          2008 NATS (National Association of Teachers of Singing) 50th National Convention
          <br />
          2008 University of TN Faculty Learning Workshop; Teaching Strategies by Dr. Anna Ball
          <br />
          2006 Clifton Ware Vocal Seminar “Adventures in Singing” Morehead State University
          <br />
          2006 The Tao of Voice Workshop, New York City, 2006
          <br />
          2004 Estill Voice Model Level One Course, New England Conservatory, Boston, MA
          <br />
          2003 NATS Vocology Workshop, Westminster Choir College, Princeton, NJ
        </p>
        <h3 className='underline'>Teaching Experience</h3>

        <h4 className='bold'>Summer 2017, 2018, 2019 Berlin Opera Academy & Saluzzo Opera Academy</h4>
        <p className='mb-2'>Berlin, Germany – Faculty - Summer opera academy for young singers; 26 nations represented. Voice lessons, mock auditions, opera role preparation, concert repertoire/art song in two months.
        </p>
        <h4 className='bold'>Summer 2022, 2023 Berlin Opera Academy</h4>
        <p className='mb-2'>Berlin, Germany – Faculty - Summer opera academy for young singers; 26 nations represented. Voice lessons, mock auditions, opera role preparation, concert repertoire/art song, Audition Preparation Sessions.
        </p>
        <h4 className='bold'>Summer 2018, 2019 AIMS American Institute of Musical Studies</h4>
        <p className='mb-2'>Graz, Austria - Guest Faculty - One week of aria coaching/mock auditions and master classes.</p>
        <h4 className='bold'>July 2010, 2011, 2014 Amalfi Coast Music & Arts Festival</h4>
        <p className='mb-2'>Vietri sul Mare, Italy, Maiori, Italy
        Summer festival for young singers; two weeks of voice lessons, role and opera scene
        preparation for concerts and opera production. 2014: Stage Director “Suor Angelica”</p>
        <h4 className='bold'>2005-2020 University of Tennessee at Knoxville, Associate Professor of Voice (2011) (Professional Development Leave – August 2012-May 2013)</h4>
        <p className='mb-2'>Private Voice Lessons to both undergraduate and graduate students, weekly master class, Undergraduate Seminar, Undergraduate Vocal Literature Survey Class, Independent Studies in Vocal Pedagogy and Diction. Recital and opera role preparation. Faculty organizer and Director for VolOpera since 2015; undergraduate opera workshop. Scenes programs, “Marriage of Figaro” with orchestra and narration (1.5 hours), World Premiere of “Pinocchio” by Aaron Hunt.</p>
        <h4 className='bold'>2004 Wake Forest University, Winston-Salem, NC, Visiting Lecturer</h4>
        <p className='mb-2'>Sabbatical replacement for Spring semester. Private voice lessons (20 students), weekly
        Master class. Vocal Director of the Spring Musical, Into the Woods.</p>
        <h4 className='bold'>2004 Wake Forest House, Vienna, Austria, Guest Lecture</h4>
        <p className='mb-2'>Presented a lecture on “Entartet” (Decadent/Banned/Jewish) Composer Franz Schrecker in preparation for attendance of American students at the Vienna Volksoper performance of “Irrelohe”.</p>
        <h4 className='bold'>2003 Wake Forest University, Winston-Salem, NC, Visiting Lecturer</h4>
        <p className='mb-2'>Sabbatical replacement for Spring semester. Private voice lessons (20 students), weekly
        Master Class, Diction Class (Italian, French, German). Senior recital preparation.</p>
        <h4 className='bold'>1992 Wake Forest University, Winston-Salem, NC, Assistant Professor</h4>
        <p className='mb-2'>Sabbatical replacement for Fall semester. Private lessons, weekly master class, Theatrical Singing course with a final staged performance of scenes. Assisted in preparation of music for the Fall production in the drama department. Recital preparation for students.</p>
        <h4 className='bold'>1990, 1993, 2003 Berkshire Choral Festival, Sheffield, MA, Faculty</h4>
        <p className='mb-2'>Private lessons, Voice classes, Choral sectional rehearsals, Diction classes in Latin, German and Italian. Historical Singers Survey class. 5 week summer festival.</p>
        <h4 className='bold'>1982-1985 Clark University, Worcester, MA, Part-Time Lecturer in Voice</h4>
        <p className='mb-2'>Private lessons, Master Classes, Recital preparation.</p>
        <h4 className='bold'>1981-1982 New England Conservatory of Music Teaching Assistantship, Boston, MA</h4>
        <p className='mb-2'>Private lessons to non-voice majors.</p>
        <h4 className='bold'></h4>
        <p className='mb-2'></p>
        <OperaRep />
        <ConcertRep />
        <CompaniesPerformed />
        <Adjudicating />
        <PerfUofT />
        <MembershipService />
        <MasterClass />
        <StudentAccomp />
        <AwardsGrants />
        <InternationalExp />
        <div style={{textAlign: 'center', marginBottom:'50px'}}>
          <h3 style={{ textDecoration: 'underline' }}>Professional CD Recordings</h3>
          <p>John Harbison’s Full Moon in March – Opera Boston Orchestra, Gil Rose, Conductor – Bmop/Sound released April 2009</p>
          <p>Mascagni’s Silvano – Bohuslav Martinu Philharmonic, Peter Tiboris, Conductor, - Elysium Recording released 1995</p>
          <p>These are professional recordings which are listed in arkivmusic.com.</p>
          <a href="#top"><button className="home-bio__more-button">Back to Top</button></a>
        </div>
      </div>
    </div>
  )
}
