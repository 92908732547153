import React from "react";
import "./HomeBio.css";
import { useNavigate } from "react-router-dom";


export default function HomeBio(props) {
 const navigate = useNavigate();
   const MoreClick = () => {
    navigate("/about");
  };

 

  return (
    <div className="home-bio__container">
      <p>Lorraine DiSimone, Mezzo Soprano, appeared extensively in concert as well as on the opera stage. She has performed with such orchestras as the New York Philharmonic, the Pittsburgh Symphony, Hartford Symphony, Omaha Symphony, Cincinnati Chamber Orchestra, Knoxville Symphony, and Shreveport Symphony. She also sang as a soloist with the Augsburg Philharmonic, North German Philharmonic and the Bayerischer Rundfunk Chamber Orchestra.
      She has sung over 50 opera roles including Rosina in Rossini’s Barber of Seville, Octavian in Strauss’ Der Rosenkavalier, Composer in Strauss’ Ariadne auf Naxos, Elizabeth Proctor in Ward’s The Crucible, Santuzza in Mascagni’s Cavalleria Rusticana, Preziosilla in Verdi’s La Forza del Destino, La Zia Principessa in Puccini’s Suor Angelica, Wellgunde and Gerhilde in Wagner’s Ring as well as Brangäne in Tristan und Isolde.  She performed with the State Theater of Augsburg , Ulm Opera, 
      Prague State Opera as well as many U.S. opera companies such as Glimmerglass Opera, Boston Lyric Opera, Sarasota Opera, New York Grand Opera, DiCapo Opera and Center for Contemporary Opera.
      Lorraine has been a private voice teacher and a member of voice faculties at various institutions for over 40 years.  Her passion for teaching matches her passion for performing.</p>
      
 <button onClick={MoreClick} className="home-bio__more-button">
        MORE
      </button>
     
    </div>
  );
}
