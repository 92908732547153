import React from "react";
import "./HeroBanner.css";

import HeroCutout from "../../assets/larger.png";

export default function HeroBanner() {
  return (
    <div className="hero-banner__container">
      <img className="hero-cutout" src={HeroCutout} alt="headshot" />
      <p className="hero__name">Lorraine DiSimone</p>
      <p className="hero__title">
      Voice Teacher
      </p>
    </div>
  );
}
