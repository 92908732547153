const AdjudicatingDatabase = [
  {
    date: `Fall 2023`,
    content:`Judge for Gerda Lissner Lieder/Song Competition`
  },
  {
    date: `Spring 2019`,
    content:`NATS Regional Competition in Clarksville, TN`
  },
  {
    date: `Spring 2018`,
    content:`NATS Regional Competition in Highland Heights, KY`
  },
  {
    date: `Spring 2017`,
    content:`NATS Regional Competition in Nashville, TN`
  },
  {
    date: `Fall 2016`,
    content:`NATS TN Chapter in Jefferson City, TN`
  },
  {
    date: `Spring 2016`,
    content:`NATS Regional Competition Knoxville - HOST`
  },
  {
    date: `Spring 2015`,
    content:`MTNA Voice Competition at UT Knoxville`
  },
  {
    date: `Spring 2015`,
    content:`NATS Regional Competition in Lexington, KY`
  },
  {
    date: `Spring 2012`,
    content:`NATS Regional Competition in Murray, KY`
  },
  {
    date: `Spring 2012`,
    content:`NATSA Award Adjudicator in Murray, KY`
  },
  {
    date: `Fall 2010`,
    content:`NATS Tennessee State Competition in Jackson, TN`
  },
  {
    date: `Spring 2010`,
    content:`NATS Regional Competition in Cleveland, TN`
  },
  {
    date: `Spring 2009`,
    content:`NATS Regional Competition in Louisville, KY`
  },
  {
    date: `Spring 2009`,
    content:`NATSA Award Adjudicator, Louisville, KY`
  },
  {
    date: `Spring 2008`,
    content:`NATS Regional Competition in Memphis, TN`
  },
  {
    date: `Spring 2007`,
    content:`NATS Regional Competition in Lexington, KY`
  },
  {
    date: `Spring 2006`,
    content:`NATS Regional Competition in Morehead, KY`
  },
  {
    date: `Spring 2008`,
    content:`Memphis Choral Competition held at UT Knoxville`
  },
  {
    date: `Spring 2007`,
    content:`Joy of Music School singing competition, Knoxville, TN`
  },
  {
    date: `Spring 2007`,
    content:`High School Singers Competition sponsored by Classical Singer Magazine`
  },
  {
    date: `Fall 2005`,
    content:`Georgia Music Educator Vocal Competition/GMTA/MTNA at University of West Georgia in Carrlton, GA`
  },
]
export default AdjudicatingDatabase


















