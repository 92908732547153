import React from 'react'
import "./CompaniesPerformed.css"
import operaCompanyOrch from "../../../../database/operaCompanyOrch.js"

export default function CompaniesPerformed() {
  
  return (
    <div>
    <h3 className='underline'>Opera Companies and Orchestras</h3>
      {operaCompanyOrch.map((company) => (
        <>
          <p>{company.company}</p>
        </>
    ))}
    </div>
  )
}
