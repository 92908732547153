import React from "react";
import "./About.css";

export default function About(props) {

  return (
    <div className="about__container">
      <h1 className="about__title">About</h1>
      <div className="about__bio-text">
      
      </div>
      <p>Lorraine DiSimone, Mezzo Soprano, appeared extensively in concert as well as on the opera stage. She has performed with such orchestras as the New York Philharmonic, the Pittsburgh Symphony, Hartford Symphony, Omaha Symphony, Cincinnati Chamber Orchestra, Knoxville Symphony and Shreveport Symphony. She has sung over 50 opera roles including Rosina in Rossini’s Barber of Seville, Octavian in Strauss’ Der Rosenkavalier, Composer in Strauss’ Ariadne auf Naxos, Elizabeth Proctor in Ward’s The Crucible, Santuzza in Mascagni’s Cavalleria Rusticana, Preziosilla in Verdi’s La Forza del Destino, La Zia Principessa in Puccini’s Suor Angelica, Wellgunde and Gerhilde in Wagner’s Ring as well as Brangäne in Tristan und Isolde.</p>
      <div className="about__live-img-holder">
      <img className="about__live-img" src="https://res.cloudinary.com/bobalobbadingdong/image/upload/v1725821066/Lorraine%20DiSimone/About/Live1_5fda9070eb357e7e36c8_iuwoym_dnpnsy.jpg" alt="live1" />
      <img className="about__live-img" src="https://res.cloudinary.com/bobalobbadingdong/image/upload/v1725821067/Lorraine%20DiSimone/About/Live2_bf01f87d43d499858e25_j2r7tl_ua6yta.jpg" alt="live2" />
      <img className="about__live-img" src="https://res.cloudinary.com/bobalobbadingdong/image/upload/v1725821065/Lorraine%20DiSimone/About/Live3_910330ce1d9ba3d56907_k6slch_mtfsh7.jpg" alt="live3" />
      </div>
      <p>Ms. DiSimone made her Carnegie Hall debut in Verdi's Nabucco and returned to perform in works of Beethoven, Handel, Mascagni, Mozart, etc. She has sung with many U.S. companies, including Glimmerglass Opera, Boston Lyric Opera, Opera Boston, New York Grand Opera, Houston Grand Opera, Des Moines Metro Opera, Chautauqua Opera, Knoxville Opera and Sarasota Opera. Ms. DiSimone made her European debut in concert at La Fenice in Venice, Italy.. She sang Maddalena to Marcello Giordani’s Duke in Rigoletto in Spoleto, Italy. Ms. DiSimone was a member of the solo ensemble in Augsburg, Germany for 3 years and also performed there as a guest. She also sang with the Ulm Opera and Prague State Theater.</p>
      <div className="about__live-img-holder">
                        <img className="about__live-img" src="https://res.cloudinary.com/bobalobbadingdong/image/upload/v1725821068/Lorraine%20DiSimone/About/Forza_59c98285a80585984b2e_d3koz6_thzxth.png" alt="Forza" />
      </div>
      <p>Ms. DiSimone has also been active in performing contemporary music. She has performed with the Composers in Red Sneakers, Center for Contemporary Opera (NYC), Lincoln Center Outdoors, and Symphony Space concerts as well as with the Bayerische Rundfunk Kammerorchester in Munich. A finalist in the Metropolitan Opera Auditions, Ms. DiSimone has taught on the faculties of University of TN Knoxville, Clark University, Wake Forest University, the Berkshire Choral Festival and the Amalfi Coast Music Festival. Most recently, she has been on the faculty of Berlin Opera Academy (2017, 2018, 2019, 2022) as well as a guest teacher with AIMS in Graz, Austria in 2018 and 2019.</p>
                      <div className="about__live-img-holder">
                                        <img className="about__live-img" src="https://res.cloudinary.com/bobalobbadingdong/image/upload/v1725821068/Lorraine%20DiSimone/About/butterfly_7e9ff25418cf23a61123_sffok3_tkyorg.png" alt="Butterfly" />
                              
                                        <img className="about__live-img" src="https://res.cloudinary.com/bobalobbadingdong/image/upload/v1725821065/Lorraine%20DiSimone/About/Nabucco_1685d377514e8a3110a7_oyyviu_ojrdpk.png" alt="Nabucco" />
                                      </div>
                                      <h1 className="about__title"> Albums</h1>
                                      <div className="about__cd-container">
                                        <div className="black rounded" >
                                        <img className="about__cd-img" src="https://res.cloudinary.com/bobalobbadingdong/image/upload/v1725822256/Lorraine%20DiSimone/Albums/Silvano_0810a3a7e4ad0503f1f5_kahhmj_p7vevi.jpg" alt="silviano" />
                                          <p>Silvano</p>
                                        <p >Role: Rosa</p>
                                        <div  onClick={()=>{window.open(`https://www.amazon.com/Silvano-P-Mascagni/dp/B000025Y77`, '_blank')}} className="about__button-amazon"><p>View On Amazon</p></div>
        </div>
        <div className="black rounded" >
                                        <img className="about__cd-img" src="https://res.cloudinary.com/bobalobbadingdong/image/upload/v1725822256/Lorraine%20DiSimone/Albums/FullMoonMarch_e09146f7876047118426_os3hqx_mrr8ql.jpg" alt="Full Moon" />
                                          <p>Full Moon</p>
                                        <p >Role: Queen</p>
                                        <div  onClick={()=>{window.open(`https://www.amazon.com/John-Harbison-Full-Moon-March/dp/B001URRI84`, '_blank')}} className="about__button-amazon"><p>View On Amazon</p></div>
        </div>
        </div>
    </div>
    
  );
}