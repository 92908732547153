const ConcertRep = [
  {
    composer: `Aldridge`,
    piece:`Parable of the Blind`
  },
  {
    composer: `Bach`,
    piece:`Christmas Oratorio`
  },
  {
    composer: `Bach`,
    piece:`Cantata 142`
  },
  {
    composer: `Bach`,
    piece:`Magnificat`
  },
  {
    composer: `Bach`,
    piece:`St. John Passion`
  },
  {
    composer: `Beethoven`,
    piece:`Egmont Incidental Music Mass in C Major`
  },
  {
    composer: `Beethoven`,
    piece:`Choral Fantasy`
  },
  {
    composer: `Beethoven`,
    piece:`Symphony No. 9`
  },
  {
    composer: `Beethoven`,
    piece:`Mass in C Major`
  },
  {
    composer: `Berlioz`,
    piece:`La Mort de Cléopâtre`
  },
  {
    composer: `Berlioz`,
    piece:`Nuits d’été`
  },
  {
    composer: `Berlioz`,
    piece:`Roméo et Juliette`
  },
  {
    composer: `Bernstein`,
    piece:`Arias and Barcarolles`
  },
  {
    composer: `Buxtehude`,
    piece:`Magnificat`
  },
  {
    composer: `Carissimi`,
    piece:`Jeptha`
  },
  {
    composer: `Carnes`,
    piece:`Two Songs (Premiere)`
  },
  {
    composer: `Copland`,
    piece:`In the Beginning`
  },
  {
    composer: `Durufle`,
    piece:`Requiem`
  },
  {
    composer: `Dvorak`,
    piece:`Stabat Mater`
  },
  {
    composer: `Egk, Werner`,
    piece:`Le Tentation de Saint Antoine`
  },
  {
    composer: `Händel`,
    piece:`Messiah`
  },
  {
    composer: `Händel`,
    piece:`Judas Maccabeus`
  },
  {
    composer: `Händel`,
    piece:`Samson`
  },
  {
    composer: `Haydn`,
    piece:`Lord Nelson Mass`
  },
  {
    composer: `Haydn`,
    piece:`Mass in Time of War`
  },
  {
    composer: `Haydn`,
    piece:`St. Nicolas Mass`
  },
  {
    composer: `Kodàly`,
    piece:`Missa Brevis`
  },
  {
    composer: `Korn, Peter Jona`,
    piece:`Von Krieg und Frieden`
  },
  {
    composer: `Mader, Richard`,
    piece:`Concerto Vocale`
  },
  {
    composer: `Mahler`,
    piece:`2nd Symphony`
  },
  {
    composer: `Mahler`,
    piece:`Songs of a Wayfarer`
  },
  {
    composer: `Monteverdi`,
    piece:`Magnificat`
  },
  {
    composer: `Mozart`,
    piece:`C minor Mass`
  },
  {
    composer: `Mozart`,
    piece:`Coronation Mass`
  },
  {
    composer: `Mozart`,
    piece:`Regina Coeli`
  },
  {
    composer: `Mozart`,
    piece:`Requiem`
  },
  {
    composer: `Mozart`,
    piece:`Solemn Vespers`
  },
  {
    composer: `Pärt`,
    piece:`Miserere`
  },
  {
    composer: `Ravel`,
    piece:`Shéhérazade`
  },
  {
    composer: `Ravel`,
    piece:`Trois Poèmes de Stephane Mallarmé`
  },
  {
    composer: `Reich`,
    piece:`Music for Eighteen Musicians`
  },
  {
    composer: `Rossini`,
    piece:`Petite Messe Solennelle`
  },
  {
    composer: `Satie`,
    piece:`Socrate`
  },
  {
    composer: `Thomson, Virgil`,
    piece:`Three Songs`
  },
  {
    composer: `Tippet`,
    piece:`A Child of Our Time`
  },
  {
    composer: `Verdi`,
    piece:`Requiem`
  },
  {
    composer: `Vivaldi`,
    piece:`Gloria`
  },
  {
    composer: `Wagner`,
    piece:`Wesendonck Lieder`
  },
  {
    composer: `Würz`,
    piece:`Sonnengesang`
  },
]

export default ConcertRep