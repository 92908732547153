import React, { useState, useEffect } from "react";
import "./App.css";
import MainContainer from "./containers/MainContainer";
import Layout from "./layouts/Layout";


import MediaImageCarousel from "./database/MediaImageCarousel.js"
import OperaReviews from "./database/OperaReviews.js"
import ConcertReviews from "./database/ConcertReviews.js"
import TestimonialsData from "./database/TestimonialsData.js";

function App() {
  const [homeText, setHomeText] = useState([]);
  const [aboutParagraphs, setAboutParagraphs] = useState([])
  const [aboutPhotos, setAboutPhotos] = useState([])
  const [cds, SetCds] = useState([])
  const [teachPhilText, setTeachPhilText] = useState([])
  const [mediaYoutubeLinks, setMediaYOutubeLinks] = useState([])
  const [songs, setSongs] = useState([])


  

  return (
    <Layout>
      <MainContainer
        homeText={homeText}
        aboutParagraphs={aboutParagraphs}
        aboutPhotos={aboutPhotos}
        cds={cds}
        teachPhilText={teachPhilText}
        operaReviews={OperaReviews}
        concertReviews={ConcertReviews}
        testimonialsData={TestimonialsData}
        mediaCarouselPics={MediaImageCarousel}
        mediaYoutubeLinks={mediaYoutubeLinks}
        songs={songs}
      />
    </Layout>
  );
}

export default App;
