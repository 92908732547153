import React from "react";
import "./TeachingPhilosophy.css";

export default function TeachingPhilosophy(props) {
  return (
    <div className="teach-phil__container">
     <h1 className="teach-phil__title">Teaching Philosophy</h1>
      <div className="teach-phil__body">
        <div className="teach-phil__text-holder">
         <p>Every voice teacher brings a unique tool kit to the voice lesson; a summation of the experience of teaching many years with many different voices and backgrounds, the accumulated knowledge that comes with decades of active professional singing, and the understanding that comes from study and investigation of the science of the voice. My goal for each student is to obtain a balance and coordination between the many interconnected elements of vocal production and thereby develop a mastery of vocal technique which can serve the music.</p>
      <p>I have a philosophy of healthy singing which involves clean, balanced onsets, resonance, elasticity and organic breath resource. The challenge of imparting this knowledge to a student involves discovering the magical mix of imagery and hard fact which will illuminate concepts and bring us to efficient, expressive, beautiful singing.
      </p>
<p>I am happy that I have over 35 years of experience performing on stages in Europe and the U.S. in opera, concert and recital work. While I do not believe that it is a prerequisite for a teacher to have had a career as a performer, I am grateful for the broad performing experience which I have enjoyed. It has deepened my understanding of the singing art and it gives me authority to advise students on the practical challenges of building a career.</p>
        <p>Just as life is a process, so is singing. There are turns in the road and evolution and discoveries along the path. My tool kit is full and I look forward to sharing it all with you.</p>
        </div>
      <div className="teaching-phil__email-btn-holder">
      <a
      href="mailto:lorrainedisimone@gmail.com"
      target="_blank"
      rel="noreferrer"
      role="button"
      className="teaching-phil__email-btn"
      >
      Email Me
      </a>
      </div>
      </div>
      </div>
      );
    }
