import React from 'react'
import MembershipServiceData from '../../../../database/MembershipServiceData'

export default function MembershipService() {
  return (
    <div>
    <h3 className='underline'>Membership and Service</h3>
    {MembershipServiceData.map((item) => (
      <>
        <p>{item.item}</p>
      </>
  ))}
    </div>
  )
}
